import React from "react"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  setTimeout(() => navigate('/'), 4000)

  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className='not-found'>
        <h1 style={{ paddingTop: 25 }}>Oops!</h1>
        <h3>
          <p>Looks like you took a wrong turn, friend.</p>
          <p>You'll be redirected back to the home page.</p>
        </h3>
      </div>
    </Layout>
  )
}

export default NotFoundPage
